import { useEffect, useState } from "react";
import BasicTextInput from "../../../components/InputFields/BasicTextInput";
import {
  FlexItem,
  CardHeader,
  Title,
  Form,
  ActionGroup,
  Button,
  FormGroup,
} from "@patternfly/react-core";
import { DateInput } from "../../../components/InputFields";
import moment from "moment";
import _ from "lodash";

interface I_FIELDS {
  label: string;
  key: string;
  type: string;
}

const Variables = ({ inputs, outputs, onSaveVariables }): JSX.Element => {
  const fields: I_FIELDS[] = [
    {
      label: "Internal Announcement Date",
      key: "internalAnnouncementDate",
      type: "date",
    },
    {
      label: "Public Announcement Date",
      key: "publicAnnouncementDate",
      type: "date",
    },
    {
      label: "Service Def Url",
      key: "serviceDefUrl",
      type: "text",
    },
    {
      label: "SLO Url",
      key: "sloUrl",
      type: "text",
    },
    {
      label: "Scripts Url",
      key: "scriptsUrl",
      type: "text",
    },
    {
      label: "Log Url",
      key: "logUrl",
      type: "text",
    },
    {
      label: "Instruct Url",
      key: "instructUrl",
      type: "text",
    },
    {
      label: "Service Status Url",
      key: "serviceStatusUrl",
      type: "text",
    },
    {
      label: "Helpful Url",
      key: "helpfulUrl",
      type: "text",
    },
  ];

  const [formValues, setFormValues] = useState(
    _.has(outputs, "variables")
      ? outputs.variables
      : inputs.managedServiceRequest.variables
  );

  const onSave = () => {
    const payload = {
      variables: { ...formValues },
    };
    onSaveVariables(payload, true);
  };

  useEffect(() => {
    if (!_.has(outputs, "variables") || outputs.variables === null) {
      onSaveVariables(
        { variables: inputs.managedServiceRequest.variables },
        false
      );
    }
  }, []);

  return (
    <FlexItem>
      <CardHeader>
        <Title headingLevel="h3" size="xl">
          Variables
        </Title>
      </CardHeader>

      <Form>
        {fields.map(({ label, key, type }) => {
          if (type === "date") {
            return (
              <FormGroup id={key} label={label} isRequired={false}>
                <DateInput
                  value={formValues[key]}
                  id={key}
                  setValue={(value) => {
                    setFormValues({
                      ...formValues,
                      [key]: moment(value).toISOString(),
                    });
                  }}
                />
              </FormGroup>
            );
          } else {
            return (
              <BasicTextInput
                style={{ width: "70%" }}
                maxLength={180}
                isRequired={false}
                id={key}
                label={label}
                value={formValues[key]}
                setValue={(value) => {
                  setFormValues({ ...formValues, [key]: value });
                }}
              />
            );
          }
        })}

        <ActionGroup>
          <Button
            onClick={onSave}
            style={{ fontWeight: "500" }}
            variant="primary"
          >
            Save
          </Button>
        </ActionGroup>
      </Form>
    </FlexItem>
  );
};
export default Variables;
